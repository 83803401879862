<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#df0007"
    ></loading>

    <div class="container px-4 py-5" v-if="!isLoading">
      <div class="position-relative back-button" v-if="cateId > 0">
        <div class="position-absolute top-0 end-0">
          <a href="/training-subcontract"
            ><i class="bi bi-house"></i> &nbsp;&nbsp;&nbsp; กลับหน้าหลัก</a
          >
        </div>
      </div>
      <h4 class="pb-2 border-bottom ">{{ title }}</h4>

      <div class="list-group">
        <div v-for="(item, index) in content" :key="index">
          <button
            type="button"
            class="mt-3 list-group-item list-group-item-action bg-secondary text-white"
            aria-current="true"
          >
            {{ item.data.TS_TEXT }}
          </button>

          <div v-for="(item2, index2) in item.c" :key="index2">
            <a
              v-if="item2.data.TS_URL !== null"
              :href="item2.data.TS_URL"
              :target="item2.data.TS_TYPE === 'ITEM' ? '_blank' : ''"
              class="list-group-item list-group-item-action"
            >
              <a
                v-if="item2.data.TS_URL !== null"
                class="badge bg-primary rounded-pill mr-3"
                ><i class="bi bi-arrow-up-right-square"></i
              ></a>

              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
              {{ item2.data.TS_TEXT }}</a
            >

            <button
              type="button"
              v-if="item2.c.length > 0"
              class="list-group-item list-group-item-action fw-bold"
            >
              {{ item2.data.TS_TEXT }}
            </button>
            <button
              type="button"
              v-if="item2.c.length == 0 && item2.data.TS_URL === null"
              class="list-group-item list-group-item-action"
            >
              {{ item2.data.TS_TEXT }}
            </button>

            <div v-for="(item3, index3) in item2.c" :key="index3">
              <a
                v-if="item3.data.TS_URL !== null"
                :href="item3.data.TS_URL"
                target="_blank"
                class="list-group-item list-group-item-action"
              >
                <a
                  v-if="item3.data.TS_URL !== null"
                  class="badge bg-primary rounded-pill mr-3"
                  ><i class="bi bi-arrow-up-right-square"></i
                ></a>

                &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                {{ item3.data.TS_TEXT }}</a
              >

              <button
                v-if="item3.data.TS_URL === null"
                type="button"
                class="list-group-item list-group-item-action"
              >
                &nbsp;&nbsp;&nbsp; {{ item3.data.TS_TEXT }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <table class="table ">


      
      <tr v-for="(item, index) in content" :key="index">
        <td class="  "><b>{{ item.data.TS_TEXT }}</b>
<div class="pl-5" v-if="item.c.length > 0">
 <table class="table ">
   <tr v-for="(item2, index2) in item.c" :key="index2">


 <td class="pl-4 ">&nbsp;&nbsp;&nbsp;{{ item2.data.TS_TEXT }}


<div class="pl-5" v-if="item2.c.length > 0">
 <table class="table">
   <tr v-for="(item3, index3) in item2.c" :key="index3">


 <td class="pl-4 ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item3.data.TS_TEXT }}




   
 </td>

   </tr>
 </table>
          </div>



 </td>

   </tr>
 </table>
          </div>
        </td>
      </tr>
      
      </table> -->
    </div>
  </div>
</template>
<script>
import AuthService from "../services/auth";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TrainingSubContract",
  data() {
    return {
      cateId: 0,
      title: "",
      content: null,
    };
  },
  components: {
    Loading,
  },
  computed: {
    getCateId() {
      // We will see what `params` is shortly
      return this.$route.params.id;
    },
  },
  service: null,
  created() {
    let today = new Date();
    let tomonth = today.getMonth() + 1;
    let month = tomonth < 10 ? "0" + tomonth : tomonth;
    let todate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    this.todayStr = today.getFullYear() + "-" + month + "-" + todate;
    this.service = AuthService;
  },
  mounted() {
    this.isLoading = true;
    this.cateId = this.getCateId;
    this.service.getTrainingSubContract(this.cateId).then((data) => {
      console.log(data.data);
      this.title = data.title;
      this.content = data.data;
    });
    this.isLoading = false;
  },
};
</script>
<style scoped>
.back-button a {
  margin-top: -30px;
  text-decoration: none;
  color: #000;
}
</style>
