<template>
  <section class="shadow-sm bg-white d-none d-md-block">
    <div class="container">
      <header class="d-flex flex-wrap justify-content-center">
        <div class="d-none">
          <!-- d-md-block -->
          <ul class="nav nav-pills">
            <li class="nav-item">
              <router-link
                to="/"
                :class="['nav-link', { active: currentPath === '/' }]"
              >
                {{ currentLang === "th" ? "หน้าหลัก" : "Home" }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/b-news"
                :class="['nav-link', { active: currentPath === '/b-news' }]"
              >
                {{ currentLang === "th" ? "กิจกรรม" : "Activity" }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/b-reward"
                :class="['nav-link', { active: currentPath === '/b-reward' }]"
              >
                {{
                  currentLang === "th" ? "ของรางวัล" : "Rewards"
                }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/b-connect"
                :class="['nav-link', { active: currentPath === '/b-connect' }]"
              >
                {{
                  currentLang === "th" ? "รวมลิงค์" : "B-Connect"
                }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/b-point"
                :class="[
                  'nav-link',
                  {
                    active:
                      currentPath === '/b-point' || currentPath === '/login',
                  },
                ]"
              >
                {{ currentLang === "th" ? "โปรไฟล์" : "Profile" }}</router-link
              >
            </li>
            <li class="nav-item">
              <router-link @click="switchLang()" to="#" :class="['nav-link']">
                {{ currentLang === "th" ? "ภาษา" : "Language" }}</router-link
              >
            </li>
          </ul>
        </div>
      </header>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    currentPath() {
      // We will see what `params` is shortly
      return this.$route.path;
    },
    currentLang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    switchLang() {
      console.log(this.currentLang);
      if (this.currentLang == "th") {
        this.$store.dispatch("changeLang", "en");
      } else {
        this.$store.dispatch("changeLang", "th");
      }
    },
  },
};
</script>
<style scoped>
header img {
  margin-left: 20px;
}
</style>
