<template>
  <div class="nav-scroller bg-success shadow text-white">
    <div class="container">
      <nav class="nav nav-underline">
        <router-link
          to="/covid-19/status"
          :class="['nav-link', { active: currentPath === '/covid-19/status' }]"
        >
          {{ currentLang === "th" ? "อัพเดทสถานะ" : "Status Update" }}
        </router-link>
        <!-- <router-link
          to="/covid-19/chat"
          :class="['nav-link', { active: currentPath === '/covid-19/chat' }]"
        >
          {{ currentLang === "th" ? "สอบถาม" : "Support Chat" }}
        </router-link> -->
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentPath() {
      // We will see what `params` is shortly
      return this.$route.path;
    },
    currentLang() {
      return this.$store.state.lang;
    },
  },
};
</script>

<style scoped>
.nav-link {
  color: #fff !important;
  padding: 10px 20px;
}
.nav-link.active {
  color: #fff;
  background: none;
  border-bottom: 5px solid #0b5c36;
}
</style>
