import { createStore } from "vuex";
import { auth } from "./auth";
export default createStore({
  state: { lang: "th", returnUrl: "/b-point" },
  mutations: {
    changeLang(state, lang) {
      state.lang = lang;
    },
    changeRedirect(state, url) {
      state.returnUrl = url;
    },
  },
  actions: {
    changeLang({ commit }, lang) {
      commit("changeLang", lang);
    },
    changeRedirect({ commit }, url) {
      commit("changeRedirect", url);
    },
  },
  modules: { auth },
});
