<template>
  <div>
    <div class="container">
      <div :class="['form signUp', 'shadow']" v-if="popRegister">
        <h3 class="text-red fw-bold m-0 p-0 my-2">ลืมรหัสผ่าน</h3>
        <p class="">กรุณากรอกข้อมูล<br />เพื่อทำการตั้งรหัสผ่านใหม่</p>
        <input
          type="tel"
          placeholder="รหัสพนักงานของคุณ"
          reqired
          autocomplete="off"
          v-model="inputRegEmpCode"
          v-mask="'########'"
        />
        <input
          type="password"
          placeholder="เลขประจำตัวประชาชน 2 ตัวสุดท้าย"
          reqired
          autocomplete="off"
          v-model="inputRegIdCard"
          v-mask="'##'"
        />
        <input
          type="password"
          v-model="inputRegPin"
          placeholder="รหัสผ่าน (ตัวเลข 6 หลัก)"
          v-mask="'######'"
          reqired
        />
        <input
          type="password"
          v-model="inputRegPinConfirm"
          placeholder="ยีนยันรหัสผ่าน"
          v-mask="'######'"
          reqired
        />

        <button class="form-btn dx " @click="handleRegister" type="button">
          รีเซตรหัสผ่าน &rarr;
        </button>
      </div>
      <div
        @submit="handleLogin"
        v-if="popLogin"
        :class="['form signIn', 'shadow']"
      >
        <h3 class="text-red fw-bold m-0 p-0 my-2 text-uppercase">
          {{ currentLang === "th" ? "ล็อคอิน" : "Login" }}
        </h3>
        <p class="">
          {{
            currentLang === "th"
              ? "กรอกข้อมูลรหัสพนักงานและรหัสผ่านที่คุณตั้งไว้"
              : "Enter your empcode and password."
          }}
        </p>
        <input
          type="tel"
          placeholder="รหัสพนักงาน"
          autocomplete="off"
          v-model="inputLoginEmpCode"
          v-mask="'########'"
          reqired
        />
        <input
          type="password"
          v-model="inputLoginPin"
          placeholder="รหัสผ่าน"
          v-mask="'######'"
          reqired
        />
        <button
          class="form-btn sx back"
          type="button"
          @click="(popLogin = false), (popRegister = true)"
        >
          {{ currentLang === "th" ? "ลงทะเบียน" : "Register" }}
        </button>
        <button class="form-btn dx" @click="handleLogin" type="button">
          {{ currentLang === "th" ? "เข้าสู่ระบบ" : "Login" }} &rarr;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgetPassword",
  components: {},

  data() {
    return {
      returnToUrl: "",
      loading: false,
      message: "",
      popLogin: false,
      popRegister: true,
      inputRegEmpCode: null,
      inputRegIdCard: null,
      inputRegPin: null,
      inputRegPinConfirm: null,
      inputLoginEmpCode: null,
      inputLoginPin: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentLang() {
      return this.$store.state.lang;
    },
    getReturnUrl() {
      return this.$store.state.returnUrl;
    },
  },
  created() {},
  mounted() {
    console.log(this.$route.query.returnUrl);
    this.returnToUrl = this.$route.query.returnUrl;
  },
  methods: {
    handleRegister() {
      let isError = false;
      let txtError = "";
      if (this.inputRegEmpCode === null || this.inputRegEmpCode === "") {
        isError = true;
        txtError += "<li>กรุณากรอกรหัสพนักงาน</li>";
      }
      if (this.inputRegIdCard === null || this.inputRegIdCard === "") {
        isError = true;
        txtError += "<li>กรุณากรอกเลขประจำตัวประชาชน 2 หลักสุดท้าย</li>";
      }
      if (
        this.inputRegPin === null ||
        this.inputRegPin === "" ||
        this.inputRegPin.length != 6
      ) {
        isError = true;
        txtError += "<li>กรุณาตั้งรหัสผ่านเป็นตัวเลข 6 หลัก</li>";
      }
      if (this.inputRegPinConfirm === null || this.inputRegPinConfirm === "") {
        isError = true;
        txtError += "<li>กรุณายืนยันรหัสผ่าน</li>";
      } else {
        if (this.inputRegPin !== this.inputRegPinConfirm) {
          isError = true;
          txtError += "<li>รหัสผ่านไม่ตรงกัน</li>";
        }
      }
      if (!isError) {
        this.message = "";
        this.successful = false;
        this.loading = true;
        let user = {
          username: this.inputRegEmpCode,
          password: this.inputRegPin,
          idcard: this.inputRegIdCard,
        };
        this.$store.dispatch("auth/register", user).then(
          (data) => {
            console.log(data);
            if (data.isSuccess === 1) {
              this.$swal({
                html:
                  "คุณสามารถล็อกอินด้วยรหัสพนักงานและรหัสที่คุณตั้งได้ทันที",
                title: "รีเซตรหัสผ่านสำเร็จ",
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  // this.$router.push("/login");
                  if (
                    this.returnToUrl === "" ||
                    this.returnToUrl === undefined
                  ) {
                    window.location.href = "https://btmt365.com";
                  } else {
                    window.location.href = this.returnToUrl;
                  }

                  // this.popLogin = true;
                  // this.popRegister = false;
                  // this.$store.dispatch("auth/login", user).then(() => {
                  //  this.$router.push("/b-point");
                  //});
                }
              });
            } else {
              txtError += "<li>" + data.errMessage + "</li>";
              this.$swal({
                html: "<ul>" + txtError + "</ul>",
                title: "พบข้อผิดพลาด",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "ตกลง",
              });
            }
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
    handleLogin() {
      let isError = false;
      let txtError = "";
      if (this.inputLoginEmpCode === null || this.inputLoginEmpCode === "") {
        isError = true;
        txtError += "<li>กรุณากรอกรหัสพนักงาน</li>";
      }
      if (this.inputLoginPin === null || this.inputLoginPin === "") {
        isError = true;
        txtError += "<li>กรุณากรอกรหัสผ่าน</li>";
      }

      if (!isError) {
        this.loading = true;
        let user = {
          username: this.inputLoginEmpCode,
          password: this.inputLoginPin,
        };
        let returnUrl = this.getReturnUrl;
        console.log(returnUrl);
        this.$store.dispatch("auth/login", user).then(
          (data) => {
            if (data.isSuccess === 1) {
              this.$router.push(returnUrl);
              //this.$router.push("/b-point");
            } else {
              txtError += "<li>รหัสพนักงานและรหัสผ่านไม่ถูกต้อง</li>";
              this.$swal({
                html: "<ul>" + txtError + "</ul>",
                title: "ล็อกอินผิดพลาด",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "ตกลง",
              });
            }
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  width: auto;
  height: auto;
  top: calc(50% - 280px);
  left: calc(50% - 187px);
}

.form {
  position: absolute;
  text-align: center;
  background: #fff;
  width: 350px;
  height: 470px;
  border-radius: 5px;
  padding: 30px 20px 0 20px;
  /* box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box; */
}

p {
  margin-bottom: 40px;
}

p > span {
  padding-top: 3px;
  display: block;
  font-weight: 400;
}

h3 {
  margin-bottom: 30px;
}

input,
button {
  outline: none !important;
}

button.form-btn {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
  border: 0;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
}

button.form-btn.sx {
  left: 0;
  border-radius: 0 0 0 5px;
  background-color: #999;
  color: #fff;
  transition: all 0.3s linear;
}

button.form-btn.sx:hover {
  background-color: #666;
  color: #fff;
}

button.form-btn.sx.back {
  background-color: #999;
  transition: all 0.3s linear;
}

button.form-btn.sx.back:hover {
  background-color: #666;
}

button.form-btn.dx {
  right: 0;
  border-radius: 0 0 5px 0;
  background-color: #df0007;
  color: #fff;
}

input {
  border: none;
  border-bottom: 1px solid #eee;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-size: 18px;
}
</style>
