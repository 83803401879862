import axios from "axios";

const API_URL = "https://proxy.btmt365.com/api.php";

class AuthService {
  login(user) {
    let formData = new FormData();
    formData.append("username", user.username);
    formData.append("password", user.password);
    formData.append("action", "login");

    return axios.post(API_URL, formData).then((response) => {
      let data = response.data;
      if (data.isSuccess === 1) {
        localStorage.setItem("user", user.username);
      }
      return data;
    });
  }
  register(user) {
    let formData = new FormData();
    formData.append("username", user.username);
    formData.append("password", user.password);
    formData.append("idcard", user.idcard);
    formData.append("action", "register");
    return axios.post(API_URL, formData).then((response) => {
      let data = response.data;
      return data;
    });
  }
  logout() {
    localStorage.removeItem("user");
  }
  getinfo(user) {
    return axios
      .get(API_URL, {
        params: {
          user: user,
          action: "getinfo",
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      });
  }
  getcoviddata(user) {
    return axios
      .get(API_URL, {
        params: {
          user: user,
          action: "getcoviddata",
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      });
  }
  updatecovid(formData) {
    return axios.post(API_URL, formData).then((response) => {
      let data = response.data;
      return data;
    });
  }
  resetcoviddata(empcode, vid) {
    return axios
      .get(API_URL, {
        params: {
          empcode,
          vid,
          action: "resetcoviddata",
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      });
  }
  uploadToS3(formData) {
    formData.append("prefix", "covid-19-campaign");
    const axiosConfig = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios
      .post("https://upload.btmt365.com/upload", formData, axiosConfig)
      .then((res) => res.data);
  }
  getchart(filter) {
    return axios
      .get(API_URL, {
        params: {
          filter: filter,
          action: "getchart",
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      });
  }
  getlucky() {
    return axios
      .get("https://proxy.btmt365.com/api2.php", {
        params: {
          action: "getlucky",
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      });
  }
  getmoderna() {
    return axios
      .get("https://proxy.btmt365.com/api2.php", {
        params: {
          action: "getmoderna",
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      });
  }
  buscheckin(formData) {
    return axios
      .post("https://proxy.btmt365.com/apibus.php", formData)
      .then((response) => {
        let data = response.data;
        return data;
      });
  }
  getTrainingSubContract(id) {
    return axios
      .get("https://proxy.btmt365.com/apisub.php", {
        params: {
          action: "getlinkV2",
          id,
        },
      })
      .then((response) => {
        let data = response.data;
        return data;
      });
  }
}

export default new AuthService();
