import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";
// import News from "../views/News.vue";
// import Reward from "../views/Reward.vue";
// import Connect from "../views/Connect.vue";
// import Profile from "../views/Profile.vue";
import Login from "../views/Login.vue";
import ForgetPassword from "../views/ForgetPassword.vue";
// import AppCovid19_Dashboard from "../views/AppCovid19_Dashboard.vue";
import AppCovid19_Status from "../views/AppCovid19_Status.vue";
// import AppCovid19_History from "../views/AppCovid19_History.vue";
// import AppCovid19_Chat from "../views/AppCovid19_Chat.vue";
import BusCheckIn from "../views/BusCheckIn.vue";
import TrainingSubContract from "../views/TrainingSubContract.vue";
import RegisterVue from "../views/Register.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  // {
  //   path: "/b-news",
  //   name: "News",
  //   component: News,
  // },
  // {
  //   path: "/b-reward",
  //   name: "Reward",
  //   component: Reward,
  // },
  // {
  //   path: "/b-connect",
  //   name: "Connect",
  //   component: Connect,
  // },
  // {
  //   path: "/b-point",
  //   name: "Profile",
  //   component: Profile,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgetpassword",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterVue,
  },
  // {
  //   path: "/covid-19/dashboard",
  //   name: "AppCovid19_Dashboard",
  //   component: AppCovid19_Dashboard,
  // },
  // {
  //   path: "/covid-19/confirm",
  //   name: "AppCovid19_Confirm",
  //   component: AppCovid19_Confirm,
  // },
  {
    path: "/covid-19/status",
    name: "AppCovid19_Status",
    component: AppCovid19_Status,
  },
  // {
  //   path: "/covid-19/history",
  //   name: "AppCovid19_History",
  //   component: AppCovid19_History,
  // },
  // {
  //   path: "/covid-19/chat",
  //   name: "AppCovid19_Chat",
  //   component: AppCovid19_Chat,
  // },
  {
    path: "/bus-checkin",
    name: "BusCheckIn",
    component: BusCheckIn,
  },
  {
    path: "/bus-checkin/:id",
    name: "BusCheckInWithId",
    component: BusCheckIn,
  },
  {
    path: "/training-subcontract",
    name: "TrainingSubContract",
    component: TrainingSubContract,
  },
  {
    path: "/training-subcontract/:id",
    name: "TrainingSubContractWithId",
    component: TrainingSubContract,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// router.beforeEach((to, from, next) => {
//   const publicPages = ["/login", "/register", "/", "/b-news", "/b-connect"];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem("user");

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next("/login");
//   } else {
//     next();
//   }
// });
export default router;
