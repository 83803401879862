<template>
  <div>
    <AppCovid19Header />
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#df0007"
    ></loading>
    <div class="container mt-3 mb-5">
      <div class="row">
        <div class="col-md-4 col-lg-3">
          <ul class="list-group mb-3">
            <li
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 0 },
              ]"
              @click="step = 0"
            >
              <i class="bi bi-caret-right-fill"></i> การคุ้มครองข้อมูล
            </li>
            <li
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 1 },
              ]"
              @click="step = 1"
            >
              <i class="bi bi-caret-right-fill"></i> อัพเดทการฉีดวัคซีน
            </li>

            <!-- <li
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 1 },
              ]"
              @click="step = 1"
            >
              <i class="bi bi-caret-right-fill"></i> อัพเดทความประสงค์
            </li>

            <li
              class="list-group-item"
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 2 },
              ]"
              @click="step = 2"
            >
              <i class="bi bi-caret-right-fill"></i> อัพเดทการจอง
            </li>
            <li
              class="list-group-item"
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 3 },
              ]"
              @click="step = 3"
            >
              <i class="bi bi-caret-right-fill"></i> ได้รับคิวการฉีดเข็มที่ 1
            </li>
            <li
              class="list-group-item"
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 4 },
              ]"
              @click="step = 4"
            >
              <i class="bi bi-caret-right-fill"></i> การฉีดเข็มที่ 1
            </li>
            <li
              class="list-group-item"
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 5 },
              ]"
              @click="step = 5"
            >
              <i class="bi bi-caret-right-fill"></i> การฉีดเข็มที่ 2
            </li>
            <li
              class="list-group-item"
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 6 },
              ]"
              @click="step = 6"
            >
              <i class="bi bi-caret-right-fill"></i> การฉีดเข็มที่ 3
            </li>
            <li
              :class="[
                'list-group-item',
                { 'list-group-item-success': step === 7 },
              ]"
              @click="step = 7"
            >
              <i class="bi bi-caret-right-fill"></i> วัคซีนทางเลือก "โมเดอร์นา"
            </li> -->
            <li class="list-group-item mb-4" @click="handleLogout">
              <i class="bi bi-caret-right-fill"></i> ออกจากระบบ
            </li>
            <li :class="['list-group-item']" v-if="isAdmin">
              <a href="#"> <b>สำหรับแอดมิน</b> </a>
            </li>
            <li :class="['list-group-item']" v-if="isAdmin">
              <a href="https://proxy.btmt365.com/download-reports.php">
                <i class="bi bi-caret-right-fill"></i> รายงานการฉีดวัคซีน</a
              >
            </li>
            <li :class="['list-group-item']" v-if="isAdmin">
              <a href="https://proxy.btmt365.com/download-reports-moderna.php">
                <i class="bi bi-caret-right-fill"></i> รายงานจองโมเดอร์นา
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-8 col-lg-9">
          <div class="card p-3" v-if="loggedIn && dataUser">
            <div class="row">
              <div class="" v-if="step === 999">
                <div class="row">
                  <div class="col-md-6">
                    <div class="card p-3 mb-3 shadow">
                      <div class="card-title">จำนวนคนลงทะเบียน</div>
                      <div class="card-body pb-1 mx-auto text-center">
                        <h1 class="text-3xl">{{ mCount }}</h1>
                        <span class="text-muted">&nbsp;</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card p-3 mb-3 shadow">
                      <div class="card-title">จำนวนโด้สที่ลงทะเบียน</div>
                      <div class="card-body pb-1 mx-auto text-center">
                        <h1 class="text-3xl">{{ mValue }}</h1>
                        <span class="text-muted">{{ mLastUpdate }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" v-if="step === 0">
                <h5 class="fw-bold text-success">การคุ้มครองข้อมูลส่วนบุคคล</h5>

                บริษัท บริดจสโตนไทร์ แมนูแฟคเจอริ่ง(ประเทศไทย) จำกัด
                มีความประสงค์เก็บรวบรวม ใช้ หรือเปิดเผย
                ข้อมูลส่วนบุคคลของท่านภายใต้เงื่อนไขดังต่อไปนี้<br />
                <b
                  >1.) รายละเอียดข้อมูลส่วนบุคคลที่บริษัทประสงค์จะเก็บรวม ใช้
                  หรือเปิดเผยจากท่าน </b
                ><br />
                รายละเอียดข้อมูลส่วนบุคคล แหล่งที่มา วัตถุประสงค์ในการจัดเก็บ
                และระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคลให้เป็นไปตามที่ปรากฎในเอกสารแนบท้ายของเอกสารให้ความยินยอมฉบับนี้<br />
                <b>2.) การเปิดเผยข้อมูลส่วนบุคคล</b><br />
                บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นให้กับบริษัทในเครือบริดจสโตน
                ได้แก่ Bridgestone Asia Pacific Pte. Ltd. และBridgestone
                Corporation หรือบริษัทอื่นๆ
                ที่ระบุเอาไว้ในเอกสารแนบท้ายของหนังสือให้ความยินยอมฉบับนี้<br />
                <b>3.) สิทธิในฐานะเจ้าของข้อมูล</b><br />
                ท่านในฐานะเจ้าของข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                พ.ศ. 2562 กำหนด มีสิทธิดังต่อไปนี้<br />
                3.1
                ขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวกับท่านซึ่งอยู่ในความรับผิดชอบของบริษัท
                หรือขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ท่านไม่ได้ให้ความยินยอม<br />
                3.2
                ขอรับข้อมูลส่วนบุคคลที่เกี่ยวกับท่านจากบริษัทได้ในรูปแบบวิธีทางอิเล็กทรอนิกส์
                รวมถึงขอให้ส่งหรือโอนข้อมูลดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่นด้วยวิธีการทางอิเล็กทรอนิกส์<br />
                3.3 คัดค้านการเก็บรวบรวม ใช้
                หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับท่าน<br />
                3.4 ขอให้บริษัทดำเนินการลบหรือทำลาย
                หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้<br />
                3.5 ขอให้บริษัท ระงับการใช้ข้อมูลส่วนบุคคลได้ <br />
                3.6 ร้องขอให้บริษัทดำเนินการให้ข้อมูลส่วนบุคคลนั้นถูกต้อง
                เป็นปัจจุบัน สมบูรณ์และไม่ก่อให้เกิดความเข้าใจผิด<br />
                3.7
                ร้องเรียนต่อคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลหากท่านเห็นว่าบริษัทไม่ได้ดำเนินการให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                พ.ศ.2562<br />
                <b>4.) การถอนความยินยอม</b><br />
                ท่านอาจเพิกถอนความยินยอมได้ทุกเมื่อโดยแจ้งให้บริษัททราบ ทั้งนี้
                การเพิกถอนความยินยอมของท่านจะไม่ส่งผลกระทบต่อการเก็บ รวบรวม ใช้
                หรือเปิดเผยข้อมูลส่วนบุคคลที่บริษัทได้ดำเนินการไปแล้วก่อนหน้านั้น
                หรือข้อมูลส่วนบุคคลที่ไม่ต้องได้รับความยินยอมตามที่กฎหมายกำหนดไว้<br />
                <b>5.) ช่องทางในการขอใช้สิทธิ</b><br />
                ท่านสามารถใช้สิทธิในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้ผ่านระบบตอบรับที่เว็บไซต์ดังต่อนี้
                http://privacy.bridgestone.co.th/pdpa<br />

                <b>6.) การพิจารณาการขอใช้สิทธิ</b><br />
                6.1
                บริษัทสงวนสิทธิในการขอให้ท่านพิสูจน์ตัวตนและจัดส่งเอกสารที่เกี่ยวข้องเพิ่มเติมก่อนที่จะเริ่มดำเนินการตามที่ท่านร้องขอ
                ในกรณีที่มีกำหนดระยะเวลาในการดำเนินการตามคำร้องขอ
                ระยะเวลาดังกล่าวจะเริ่มนับหลังจากที่บริษัทได้รับเอกสารที่เกี่ยวข้องทั้งหมดไว้แล้ว
                และบริษัทอาจปฏิเสธที่จะดำเนินการตามคำร้องขอของท่านในกรณีดังต่อไปนี้
                ได้แก่ (1.)
                กรณีบริษัทไม่ได้รับเอกสารตามที่ร้องขอภายในเจ็ดวันทำการของบริษัท
                (2.)
                กรณีท่านไม่สามารถแสดงให้เห็นอย่างชัดเจนได้ว่าท่านเป็นเจ้าของข้อมูลที่มีอำนาจในการยื่นคำร้องขอ
                (3.) กรณีบริษัทไม่มีข้อมูลส่วนบุคคลตามที่ร้องขอจัดเก็บไว้ (4.)
                กรณีคำร้องขอดังกล่าวเป็นคำร้องขอฟุ่มเฟือยหรือไม่สมเหตุสมผล หรือ
                (5.)
                กรณีข้อมูลที่ท่านร้องขอเป็นการเปิดเผยข้อมูลส่วนบุคคลของบุคคลอื่นไม่ว่าโดยตรงหรือโดยอ้อม<br />
                6.2
                บริษัทอาจพิจารณาปฏิเสธที่จะดำเนินการตามคำขอทั้งหมดหรือแต่บางส่วนของท่าน
                หากการเก็บรวบรวม ใช้ หรือ
                เปิดเผยซึ่งข้อมูลส่วนบุคคลนั้นเป็นไปเพื่อวัตถุประสงค์ที่ได้รับการยกเว้นตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                พ.ศ. 2562
                ซึ่งบริษัทจะแจ้งให้ท่านทราบพร้อมคำอธิบายว่าเพราะเหตุใดบริษัทจึงเชื่อว่าไม่ควรดำเนินการตามคำขอของท่าน<br />
                6.3 ในกรณีทั่วไป บริษัทจะให้บริการแก่ท่านโดยปราศจากค่าใช้จ่าย
                อย่างไรก็ตาม
                บริษัทอาจเรียกเก็บค่าธรรมเนียมในอัตราที่เหมาะสมสำหรับคำขอซึ่งมีลักษณะฟุ่มเฟือย
                ปราศจากมูลเหตุ หรือไม่สมเหตุสมผล<br /><br />
                เพื่อให้บริษัทบริหารความเสี่ยงในสถานการฉุกเฉินอันเกิดจากโรคระบาดโคโรน่า
                ไวรัสให้มีประสิทธิภาพและ
                หากบริษัทไม่ได้รับข้อมูลที่ถูกต้องและรวดเร็วอาจผลกระทบกับการดำเนินงานของบริษัทได้<br /><br />
                <button
                  class="btn btn-success form-control mt-3"
                  v-if="!dataUser.TCS_PDPA_ACCEPT"
                  @click="handleStep0"
                >
                  ยอมรับ
                </button>
                <button
                  class="btn btn-outline-success form-control mt-3"
                  disabled
                  v-if="dataUser.TCS_PDPA_ACCEPT === 'Y'"
                >
                  ยอมรับแล้วเมื่อ {{ dataUser.TCS_PDPA_DATETIME }}</button
                ><br />
              </div>
              <div class="card-body" v-if="step === 1">
                <h5 class="fw-bold text-success">
                  ประวัติการฉีดวัคซีน
                </h5>
                <div class="row row-xs">
                  <div
                    class="form-group col-12 col-sm-12 col-md-12 col-lg-6  mt-3"
                  >
                    <label for="exampleEmail" class="fw-bold mb-1"
                      >ชื่อ - นามสกุล</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="exampleEmail"
                      readonly
                      disabled
                      v-model="userFullName"
                    />
                  </div>
                  <div
                    class="form-group col-12 col-sm-6 col-md-6 col-lg-3 mt-3"
                  >
                    <label for="exampleEmail" class="fw-bold mb-1"
                      >เลขประจำตัวประชาชน</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="exampleEmail"
                      readonly
                      disabled
                      v-model="dataUser.EMP_ID_CARD"
                    />
                  </div>
                  <div
                    class="form-group col-12 col-sm-6 col-md-6 col-lg-3  mt-3"
                  >
                    <label for="exampleEmail" class="fw-bold mb-1"
                      >วัน/เดือน/ปี เกิด</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="exampleEmail"
                      readonly
                      disabled
                      v-model="dataUser.EMP_BIRTHDAY"
                    />
                  </div>
                </div>
                <div class="table-responsive mt-2">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>เข็มที่</th>
                        <th>สถานะ</th>
                        <th>วัคซีน</th>
                        <th>วันที่ฉีด</th>
                        <th>วันที่อัพเดท</th>
                        <th>รูปภาพ</th>
                        <th>ดำเนินการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, index) in datavaccine" :key="index">
                        <td>{{ v.id }}</td>
                        <td>{{ v.status }}</td>
                        <td>{{ v.vaccine }}</td>
                        <td>{{ v.date }}</td>
                        <td>{{ v.update }}</td>
                        <td>
                          <img
                            data-magnify="gallery"
                            :data-src="v.picture"
                            class="img-fit-cover img-thumbnail hand img-object-top rounded-left"
                            :src="v.picture"
                            alt=""
                            v-if="v.picture !== ''"
                          />
                          <!-- <a
                            v-if="v.picture !== ''"
                            data-magnify="gallery"
                            :href="v.picture"
                            class="btn btn-dark  btn-sm   justify-content-center"
                            ><i class="bi bi-image-fill"></i
                          ></a> -->
                        </td>
                        <td>
                          <button
                            @click="
                              (editVId = v.id), (editVData = v), (step = 22)
                            "
                            class="btn btn-success btn-sm  justify-content-center"
                          >
                            <i class="bi bi-pencil"></i></button
                          >&nbsp;
                          <button
                            @click="handleDelete(v.id)"
                            class="btn btn-warning btn-sm ml-2 justify-content-center"
                          >
                            <i class="bi bi-x"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <center>
                  กรณีใช้งานบนมือถือหรืออุปกรณ์ที่มีขนาดเล็ก<br />
                  กรุณา<b>เลื่อนตารางไปด้านขวา</b>เพื่อดูคอลัมน์เพิ่มเติม
                </center>
              </div>
              <div class="card-body" v-if="step === 11">
                <h5 class="fw-bold text-success">
                  สำรวจประสงค์ในการรับการฉีดวัคซีน
                </h5>
                <span class="text-primary"
                  >อัพเดทล่าสุดเมื่อ {{ dataUser.TCS_DATETIME_STEP1 }}</span
                >
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >ชื่อ - นามสกุล</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="exampleEmail"
                    readonly
                    disabled
                    v-model="userFullName"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >เลขประจำตัวประชาชน</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="exampleEmail"
                    readonly
                    disabled
                    v-model="dataUser.EMP_ID_CARD"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >วัน/เดือน/ปี เกิด</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="exampleEmail"
                    readonly
                    disabled
                    v-model="dataUser.EMP_BIRTHDAY"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="exampleSelect" class="fw-bold mb-1"
                    >ประสงค์ในการรับการฉีดวัคซีน</label
                  ><br />
                  <input
                    type="radio"
                    class="form-radio mr-3"
                    name="CF"
                    v-model="dataUser.TCS_NEED"
                    value="Y"
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;ฉีด&nbsp;&nbsp; &nbsp;&nbsp;
                  <input
                    type="radio"
                    class="form-radio mr-3"
                    name="CF"
                    v-model="dataUser.TCS_NEED"
                    value="N"
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;ไม่ฉีด
                </div>
                <div class="form-group mt-3">
                  <label for="exampleTextarea" class="fw-bold mb-1"
                    >โรคประจำตัว
                    <span class="text-red">(ข้อมูลเป็นความลับ)</span></label
                  >
                  <textarea
                    class="form-control"
                    id="exampleTextarea"
                    v-model="dataUser.TCS_CD"
                    rows="3"
                  ></textarea>
                </div>
                <div class="form-group mt-3">
                  <label for="exampleTextarea" class="fw-bold mb-1"
                    >สาเหตุที่ไม่รับการฉีดวัคซีน
                    <span class="text-red">(ข้อมูลเป็นความลับ)</span></label
                  >
                  <textarea
                    class="form-control"
                    id="exampleTextarea"
                    v-model="dataUser.TCS_NO_REMARK"
                    rows="3"
                  ></textarea>
                </div>

                <br />
                <b>หมายเหตุ</b>
                <ul>
                  <li>
                    ข้อมูลที่เป็นความลับจะดำเนินการตามข้อกำหนดในการคุ้มครองข้อมูลส่วนบุคคล
                  </li>
                  <li>
                    ขอความร่วมในการอัพเดทข้อมูลให้เป็นปัจจุบันและมีรายละเอียดมากที่สุด
                    เพื่อให้สามารถนำข้อมูลไปวิเคราะห์หรือดำเนินการในขั้นตอนต่อไปได้
                  </li>
                </ul>
                <button
                  class="btn btn-outline-success form-control mt-3"
                  disabled
                  v-if="!dataUser.TCS_PDPA_ACCEPT"
                >
                  กรุณายอมรับการคุ้มครองข้อมูลก่อน
                </button>
                <button
                  class="btn btn-success form-control mt-3"
                  v-if="dataUser.TCS_PDPA_ACCEPT === 'Y'"
                  @click="handleStep1"
                >
                  บันทึกข้อมูล
                </button>
              </div>
              <div class="card-body" v-if="step === 2">
                <h5 class="fw-bold text-success">อัพเดทสถานะการจอง</h5>
                <span class="text-primary"
                  >อัพเดทล่าสุดเมื่อ {{ dataUser.TCS_DATETIME_STEP2 }}</span
                >

                <div class="form-group mt-3">
                  <label for="exampleSelect" class="fw-bold mb-1"
                    >คุณได้มีการจองการฉีดวัคซีนไว้หรือยัง</label
                  ><br />
                  <input
                    type="radio"
                    class="form-radio mr-3"
                    name="CF"
                    v-model="dataUser.TCS_CF"
                    value="Y"
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;จองแล้ว&nbsp;&nbsp; &nbsp;&nbsp;
                  <input
                    type="radio"
                    class="form-radio mr-3"
                    name="CF"
                    v-model="dataUser.TCS_CF"
                    value="N"
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;ยังไม่ได้จอง
                </div>
                <div class="form-group mt-3">
                  <label for="exampleTextarea" class="mb-1"
                    ><b>กรณีมีการจองแล้ว</b
                    ><br />คุณจองการรับการฉีดวัคซีนช่องทางใดบ้าง (หมอพร้อม,
                    ไทยร่วมใจ, อสม, ...)
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleTextarea"
                    v-model="dataUser.TCS_CF_WHERE"
                    rows="10"
                  ></textarea>
                </div>
                <button
                  class="btn btn-outline-success form-control mt-3"
                  disabled
                  v-if="!dataUser.TCS_PDPA_ACCEPT"
                >
                  กรุณายอมรับการคุ้มครองข้อมูลก่อน
                </button>
                <button
                  class="btn btn-success form-control mt-3"
                  v-if="dataUser.TCS_PDPA_ACCEPT === 'Y'"
                  @click="handleStep2"
                >
                  บันทึกข้อมูล
                </button>
              </div>

              <div class="card-body" v-if="step === 3">
                <h5 class="fw-bold text-success">
                  จองวันลาสำหรับการฉีดเข็มที่ 1
                </h5>
                <span class="text-primary"
                  >อัพเดทล่าสุดเมื่อ {{ dataUser.TCS_DATETIME_STEP3 }}</span
                >

                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >วันที่จะเข้ารับการฉีดวัคซีน</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="exampleEmail"
                    v-model="dataUser.TCS_CF_DATE"
                  />
                </div>

                <div class="form-group mt-3">
                  <label
                    for="exampleEmail"
                    class="fw-bold mb-1 p-2 px-4"
                    style="background: yellow"
                    >เอกสารแนบสำหรับการยืนยันการจอง</label
                  >
                  <div
                    class="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div class="space-1 p-2 text-center">
                      <div class="flex text-sm text-gray-600">
                        <label
                          for="file-upload"
                          class="relative cursor-pointer bg-white rounded-md font-medium"
                        >
                          <div class="w-full py-5" v-if="submitPicUrl == null">
                            <svg
                              class="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div v-if="submitPicUrl == null">
                              อัพโหลดหรือถ่ายภาพเอกสาร
                            </div>
                          </div>
                          <div v-if="submitPicUrl" class="text-center">
                            <img :src="submitPicUrl" style="width: 100%" />
                            <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                            <div class="mt-3 text-red-700">
                              กดที่รูปเพื่อเปลี่ยนภาพ
                            </div>
                          </div>

                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            class="sr-only"
                            @change="onFileChange"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  class="btn btn-outline-success form-control mt-3"
                  disabled
                  v-if="!dataUser.TCS_PDPA_ACCEPT"
                >
                  กรุณายอมรับการคุ้มครองข้อมูลก่อน
                </button>
                <button
                  class="btn btn-success form-control mt-3"
                  v-if="dataUser.TCS_PDPA_ACCEPT === 'Y'"
                  @click="handleStep3"
                >
                  บันทึกข้อมูล
                </button>
                <hr />

                <div class="form-group mt-3" v-if="dataUser.TCS_CF_FILE">
                  <label for="exampleEmail" class="fw-bold mb-1">
                    เอกสารแนบ (อัพโหลดแล้ว)</label
                  >
                  <div class="row mt-3">
                    <div class="col-12 text-center">
                      <img :src="dataUser.TCS_CF_FILE" class="img-fluid" />
                    </div>
                    <div class="col-12 mt-3 text-center">
                      อัพโหลดเมื่อ {{ dataUser.TCS_DATETIME_STEP3 }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body" v-if="editVId !== ''">
                <h5 class="fw-bold text-success">
                  อัพเดทการฉีดเข็มที่ {{ editVId }}
                </h5>
                <span class="text-primary"
                  >อัพเดทล่าสุดเมื่อ {{ editVData.update }}</span
                >
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >วันที่เข้ารับการฉีดเข็มที่ {{ editVId }}</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="exampleEmail"
                    v-model="editVData.date"
                  />
                </div>

                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >วัคซีนที่ฉีด</label
                  >
                  <select class="form-control" v-model="editVData.vaccine">
                    <option value="">กรุณาเลือก</option>
                    <option value="sinovac">ซิโนแวค (Sinovac)</option>
                    <option value="astrazeneca">
                      แอสตราเซเนกา (AstraZeneca)
                    </option>
                    <option value="sinopharm">ซิโนฟาร์ม (Sinopharm)</option>
                    <option value="pfizer">ไฟเซอร์ (Pfizer)</option>
                    <option value="moderna">โมเดอร์นา (Moderna)</option>
                  </select>
                </div>
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >สถานที่เข้ารับการฉีด</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="exampleEmail"
                    v-model="editVData.where"
                  />
                </div>

                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1 "
                    >เอกสารแนบของการฉีดเข็มที่ {{ editVId }}</label
                  >
                  <div
                    class="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div class="space-1 text-center">
                      <div class="flex text-sm text-gray-600">
                        <label
                          for="file-upload"
                          class="relative cursor-pointer bg-white rounded-md font-medium"
                        >
                          <div
                            class="w-full py-5"
                            v-if="editVData.picture === ''"
                          >
                            <svg
                              class="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div v-if="editVData.picture === ''">
                              อัพโหลดหรือถ่ายภาพเอกสาร
                            </div>
                          </div>
                          <div v-if="editVData.picture" class="text-center">
                            <img :src="editVData.picture" style="width: 100%" />
                            <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                            <div class="mt-3 text-red-700">
                              กดที่รูปเพื่อเปลี่ยนภาพ
                            </div>
                          </div>

                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            class="sr-only"
                            @change="onFileChange4"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row row-xs">
                    <div class="col-6">
                      <button
                        class="btn btn-outline-success form-control  mt-3"
                        disabled
                        v-if="!dataUser.TCS_PDPA_ACCEPT"
                      >
                        กรุณายอมรับการคุ้มครองข้อมูลก่อน
                      </button>
                      <button
                        class="btn btn-success form-control   mt-3"
                        v-if="dataUser.TCS_PDPA_ACCEPT === 'Y'"
                        @click="handleStep4"
                      >
                        บันทึกข้อมูล
                      </button>
                    </div>
                    <div class="col-6">
                      <button
                        class="btn btn-danger form-control  mt-3"
                        @click="handleGoBack()"
                      >
                        ย้อนกลับ
                      </button>
                    </div>
                  </div>
                </div>
                <hr />

                <!-- <div class="form-group mt-3" v-if="dataUser.TCS_S1_FILE">
                  <label for="exampleEmail" class="fw-bold mb-1">
                    เอกสารแนบ (อัพโหลดแล้ว)</label
                  >
                  <div class="row mt-3">
                    <div class="col-12 text-center">
                      <img :src="dataUser.TCS_S1_FILE" class="img-fluid" />
                    </div>
                    <div class="col-12 mt-3 text-center">
                      อัพโหลดเมื่อ {{ dataUser.TCS_DATETIME_STEP4 }}
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="card-body" v-if="step === 5">
                <h5 class="fw-bold text-success">อัพเดทการฉีดเข็มที่ 2</h5>
                <span class="text-primary"
                  >อัพเดทล่าสุดเมื่อ {{ dataUser.TCS_DATETIME_STEP5 }}</span
                >
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >วันที่เข้ารับการฉีดเข็มที่ 2</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="exampleEmail"
                    v-model="dataUser.TCS_S2_DATE"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >สถานที่เข้ารับการฉีด</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="exampleEmail"
                    v-model="dataUser.TCS_S2_WHERE"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >วัคซีนที่ฉีด</label
                  >
                  <select
                    class="form-control"
                    v-model="dataUser.TCS_S2_VACCINE_NAME"
                  >
                    <option value="">กรุณาเลือก</option>
                    <option value="sinovac">ซิโนแวค (Sinovac)</option>
                    <option value="astrazeneca">
                      แอสตราเซเนกา (AstraZeneca)
                    </option>
                    <option value="sinopharm">ซิโนฟาร์ม (Sinopharm)</option>
                    <option value="pfizer">ไฟเซอร์ (Pfizer)</option>
                    <option value="moderna">โมเดอร์นา (Moderna)</option>
                  </select>
                </div>
                <div class="form-group mt-3">
                  <label
                    for="exampleEmail"
                    class="fw-bold mb-1 p-2 px-4"
                    style="background: yellow"
                    >เอกสารแนบของการฉีดเข็มที่ 2</label
                  >
                  <div
                    class="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div class="space-1 p-2 text-center">
                      <div class="flex text-sm text-gray-600">
                        <label
                          for="file-upload"
                          class="relative cursor-pointer bg-white rounded-md font-medium"
                        >
                          <div class="w-full py-5" v-if="submitPicUrl5 == null">
                            <svg
                              class="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div v-if="submitPicUrl5 == null">
                              อัพโหลดหรือถ่ายภาพเอกสาร
                            </div>
                          </div>
                          <div v-if="submitPicUrl5" class="text-center">
                            <img :src="submitPicUrl5" style="width: 100%" />
                            <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                            <div class="mt-3 text-red-700">
                              กดที่รูปเพื่อเปลี่ยนภาพ
                            </div>
                          </div>

                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            class="sr-only"
                            @change="onFileChange5"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  class="btn btn-outline-success form-control mt-3"
                  disabled
                  v-if="!dataUser.TCS_PDPA_ACCEPT"
                >
                  กรุณายอมรับการคุ้มครองข้อมูลก่อน
                </button>
                <button
                  class="btn btn-success form-control mt-3"
                  v-if="dataUser.TCS_PDPA_ACCEPT === 'Y'"
                  @click="handleStep5"
                >
                  บันทึกข้อมูล
                </button>
                <hr />

                <div class="form-group mt-3" v-if="dataUser.TCS_S2_FILE">
                  <label for="exampleEmail" class="fw-bold mb-1">
                    เอกสารแนบ (อัพโหลดแล้ว)</label
                  >
                  <div class="row mt-3">
                    <div class="col-12 text-center">
                      <img :src="dataUser.TCS_S2_FILE" class="img-fluid" />
                    </div>
                    <div class="col-12 mt-3 text-center">
                      อัพโหลดเมื่อ {{ dataUser.TCS_DATETIME_STEP5 }}
                    </div>
                  </div>
                </div>
              </div>

              <!----------  -------->
              <div class="card-body" v-if="step === 6">
                <h5 class="fw-bold text-success">อัพเดทการฉีดเข็มที่ 3</h5>
                <span class="text-primary"
                  >อัพเดทล่าสุดเมื่อ {{ dataUser.TCS_DATETIME_S3 }}</span
                >
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >วันที่เข้ารับการฉีดเข็มที่ 3</label
                  >
                  <input
                    type="date"
                    class="form-control"
                    id="exampleEmail"
                    v-model="dataUser.TCS_S3_DATE"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >สถานที่เข้ารับการฉีด</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="exampleEmail"
                    v-model="dataUser.TCS_S3_WHERE"
                  />
                </div>
                <div class="form-group mt-3">
                  <label for="exampleEmail" class="fw-bold mb-1"
                    >วัคซีนที่ฉีด</label
                  >
                  <select
                    class="form-control"
                    v-model="dataUser.TCS_S3_VACCINE_NAME"
                  >
                    <option value="">กรุณาเลือก</option>
                    <option value="sinovac">ซิโนแวค (Sinovac)</option>
                    <option value="astrazeneca">
                      แอสตราเซเนกา (AstraZeneca)
                    </option>
                    <option value="sinopharm">ซิโนฟาร์ม (Sinopharm)</option>
                    <option value="pfizer">ไฟเซอร์ (Pfizer)</option>
                    <option value="moderna">โมเดอร์นา (Moderna)</option>
                  </select>
                </div>
                <div class="form-group mt-3">
                  <label
                    for="exampleEmail"
                    class="fw-bold mb-1 p-2 px-4"
                    style="background: yellow"
                    >เอกสารแนบของการฉีดเข็มที่ 3</label
                  >
                  <div
                    class="mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div class="space-1 p-2 text-center">
                      <div class="flex text-sm text-gray-600">
                        <label
                          for="file-upload"
                          class="relative cursor-pointer bg-white rounded-md font-medium"
                        >
                          <div class="w-full py-5" v-if="submitPicUrl6 == null">
                            <svg
                              class="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div v-if="submitPicUrl6 == null">
                              อัพโหลดหรือถ่ายภาพเอกสาร
                            </div>
                          </div>
                          <div v-if="submitPicUrl6" class="text-center">
                            <img :src="submitPicUrl6" style="width: 100%" />
                            <!-- <div
                  v-if="submitPicUrl"
                  class="mt-3 uppercase overflow-ellipsis"
                >
                  {{ submitPicName }}
                </div> -->
                            <div class="mt-3 text-red-700">
                              กดที่รูปเพื่อเปลี่ยนภาพ
                            </div>
                          </div>

                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            class="sr-only"
                            @change="onFileChange6"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  class="btn btn-outline-success form-control mt-3"
                  disabled
                  v-if="!dataUser.TCS_PDPA_ACCEPT"
                >
                  กรุณายอมรับการคุ้มครองข้อมูลก่อน
                </button>
                <button
                  class="btn btn-success form-control mt-3"
                  v-if="dataUser.TCS_PDPA_ACCEPT === 'Y'"
                  @click="handleStep6"
                >
                  บันทึกข้อมูล
                </button>
                <hr />

                <div class="form-group mt-3" v-if="dataUser.TCS_S3_FILE">
                  <label for="exampleEmail" class="fw-bold mb-1">
                    เอกสารแนบ (อัพโหลดแล้ว)</label
                  >
                  <div class="row mt-3">
                    <div class="col-12 text-center">
                      <img :src="dataUser.TCS_S3_FILE" class="img-fluid" />
                    </div>
                    <div class="col-12 mt-3 text-center">
                      อัพโหลดเมื่อ {{ dataUser.TCS_DATETIME_S3 }}
                    </div>
                  </div>
                </div>
              </div>

              <!---------- 

              <div
                class="card-head bg-primary text-white text-center p-2"
                v-if="step === 7"
              >
                กรุณาอัพเดทข้อมูลก่อน
                <b class="">วันอาทิตย์ที่ 11 ก.ค. 2564 เวลา 17:00 น.</b>
              </div> -------->
              <div class="card-body" v-if="step === 7">
                <h5 class="fw-bold text-success">
                  การแสดงความประสงค์ในการรับการฉีดวัคซีนทางเลือก "โมเดอร์นา"
                  กับทางบริษัท
                </h5>
                <span class="text-primary"
                  >อัพเดทล่าสุดเมื่อ {{ dataUser.TMS_UPDATE_TIME }}</span
                >

                <div class="form-group mt-3">
                  <label for="exampleSelect" class="fw-bold mb-1"
                    >คุณประสงค์ที่จะฉีดวัคซีนทางเลือก "โมเดอร์นา" กับทางบริษัท
                    หรือไม่</label
                  ><br />
                  <input
                    type="radio"
                    class="form-radio mr-3"
                    name="CF_Moderna"
                    v-model="dataUser.TMS_NEED"
                    value="Y"
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;ฉีด&nbsp;&nbsp; &nbsp;&nbsp;<br />
                  <input
                    type="radio"
                    class="form-radio mr-3"
                    name="CF_Moderna"
                    v-model="dataUser.TMS_NEED"
                    value="N"
                    @click="dataUser.TMS_NEED_VALUE = ''"
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;ไม่ฉีด
                </div>

                <div class="form-group mt-3">
                  <label for="exampleSelect" class="fw-bold mb-1"
                    >กรณีเลือกฉีดวัคซีนทางเลือก "โมเดอร์นา" กับทางบริษัท</label
                  ><br />
                  <input
                    type="radio"
                    class="form-radio mr-3"
                    name="CF_Moderna_Type"
                    v-model="dataUser.TMS_NEED_VALUE"
                    value="2"
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;ฉีด 2 เข็ม&nbsp;&nbsp;
                  &nbsp;&nbsp;<br />
                  <input
                    type="radio"
                    class="form-radio mr-3"
                    name="CF_Moderna_Type"
                    v-model="dataUser.TMS_NEED_VALUE"
                    value="1"
                  />
                  &nbsp;&nbsp; &nbsp;&nbsp;ฉีด 1 เข็ม<br /><br /><br />
                  <b class="text-primary">คำแนะนำในการเลือก</b><br />
                  <ul>
                    <li>
                      สำหรับผู้ที่ไม่เคยฉีดวัคซีนใดๆ มาก่อน เลือก
                      <u class="text-primary">ฉีด 2 เข็ม</u>
                    </li>
                    <li>
                      สำหรับผู้ที่เคยได้รับวัคซีนโควิดมาแล้ว<br />
                      <ul>
                        <li>
                          ฉีดมาแล้ว 1 เข็ม เป็น<u>ชิโนแวค</u>หรือ<u
                            >แอสตร้าเซนนิกา</u
                          >
                          และต้องการฉีดโมเดอร์นาเป็นเข็มที่ 2 ให้เลือก
                          <u class="text-primary">ฉีด 1 เข็ม</u>
                        </li>

                        <li>
                          ฉีดมาแล้ว 2 เข็ม
                          และต้องการฉีดโมเดอร์นาเป็นเข็มกระตุ้นเข็มที่ 3
                          ให้เลือก <u class="text-primary">ฉีด 1 เข็ม</u>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <br /><br />
                  <!-- <b>เอกสารแนบ</b><br />
                  <ol>
                    <li>
                      <a
                        href="https://s3.ap-southeast-1.amazonaws.com/z.btmt365.com/file-shared/HR065-21+%E0%B9%81%E0%B8%88%E0%B9%89%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%AA%E0%B8%87%E0%B8%84%E0%B9%8C%E0%B8%88%E0%B8%AD%E0%B8%87%E0%B8%A7%E0%B8%B1%E0%B8%84%E0%B8%8B%E0%B8%B5%E0%B8%99%E0%B8%9B%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B1%E0%B8%99%E0%B9%82%E0%B8%84%E0%B8%A7%E0%B8%B4%E0%B8%94-19+%E0%B8%A7%E0%B8%B1%E0%B8%84%E0%B8%8B%E0%B8%B5%E0%B8%99%E0%B8%97%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%A5%E0%B8%B7%E0%B8%AD%E0%B8%81+%E2%80%9C%E0%B9%82%E0%B8%A1%E0%B9%80%E0%B8%94%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%99%E0%B8%B2%E2%80%9D+(Moderna).pdf"
                        target="_blank"
                        >ประกาศบริษัทเรื่องจองวัคซีนทางเลือก "โมเดอร์นา"</a
                      >
                    </li>
                    <li>
                      <a
                        href="https://s3.ap-southeast-1.amazonaws.com/z.btmt365.com/file-shared/%E0%B9%80%E0%B8%AD%E0%B8%81%E0%B8%AA%E0%B8%B2%E0%B8%A3%E0%B9%82%E0%B8%A3%E0%B8%87%E0%B8%9E%E0%B8%A2%E0%B8%B2%E0%B8%9A%E0%B8%B2%E0%B8%A5%E0%B9%80%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B9%82%E0%B8%A1%E0%B9%80%E0%B8%94%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%99%E0%B8%B2.pdf"
                        target="_blank"
                        >เอกสารเกี่ยวกับวัคซีนโมเดอร์นาจากโรงพยาบาล</a
                      >
                    </li>
                  </ol> -->
                </div>

                <button
                  class="btn btn-outline-success form-control mt-3"
                  disabled
                  v-if="!dataUser.TCS_PDPA_ACCEPT"
                >
                  กรุณายอมรับการคุ้มครองข้อมูลก่อน
                </button>
                <button
                  class="btn btn-success form-control mt-3"
                  v-if="dataUser.TCS_PDPA_ACCEPT === 'Y'"
                  @click="handleStep7"
                >
                  บันทึกข้อมูล
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppCovid19Header from "@/components/AppCovid19_Header.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AuthService from "../services/auth";

export default {
  name: "AppCovid19_Status",
  data() {
    return {
      isLoading: true,
      fullPage: true,
      dataUser: null,
      point: 0,
      AcceptPDPA: false,
      userFullName: null,
      step: 0,
      step1No: 0,
      submitPic: null,
      submitPicUrl: null,
      submitPicName: null,
      submitPic4: null,
      submitPicUrl4: null,
      submitPicName4: null,
      submitPic5: null,
      submitPicUrl5: null,
      submitPicName5: null,
      submitPic6: null,
      submitPicUrl6: null,
      submitPicName6: null,
      todayStr: null,
      mCount: 0,
      mValue: 0,
      mLastUpdate: null,
      s2DateDisable: false,
      editVId: "",
      editVData: null,
      openModal: true,
      datavaccine: [],
      vaccineName: [
        { id: "", txt: "กรุณาเลือก" },
        { id: "sinovac", txt: "ซิโนแวค (Sinovac)" },
        { id: "astrazeneca", txt: "แอสตราเซเนกา (AstraZeneca)" },
        { id: "sinopharm", txt: "ซิโนฟาร์ม (Sinopharm)" },
        { id: "pfizer", txt: "ไฟเซอร์ (Pfizer)" },
        { id: "moderna", txt: "โมเดอร์นา (Moderna)" },
      ],
      // <option value="">กรุณาเลือก</option>
      //               <option value="sinovac">ซิโนแวค (Sinovac)</option>
      //               <option value="astrazeneca">
      //                 แอสตราเซเนกา (AstraZeneca)
      //               </option>
      //               <option value="sinopharm">ซิโนฟาร์ม (Sinopharm)</option>
      //               <option value="pfizer">ไฟเซอร์ (Pfizer)</option>
      //               <option value="moderna">โมเดอร์นา (Moderna)</option>
    };
  },
  components: {
    Loading,
    AppCovid19Header,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isAdmin() {
      return (
        this.currentUser == "20120548" ||
        this.currentUser == "20200789" ||
        this.currentUser == "20040141" ||
        this.currentUser == "20100441" ||
        this.currentUser == "20110468" ||
        this.currentUser == "20180765"
      );
    },
  },
  service: null,
  created() {
    let today = new Date();
    let tomonth = today.getMonth() + 1;
    let month = tomonth < 10 ? "0" + tomonth : tomonth;
    let todate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    this.todayStr = today.getFullYear() + "-" + month + "-" + todate;
    this.service = AuthService;

    if (!this.loggedIn) {
      // this.$swal.fire({
      //   position: "bottom-center",
      //   icon: "error",
      //   title: "กรุณาล็อคอิน",
      //   html: "กรุณาเข้าสู่ระบบหรือเชื่อมต่อบัญชี<br />ก่อนใช้งานหน้าจอนี้",
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
      this.$router.push("/login");
    } else {
      this.isLoading = true;
      this.service.getmoderna().then((data) => {
        this.mCount = data.regData.regCount;
        this.mValue = data.regData.regValue;
        this.mLastUpdate = data.regData.regLastUpdate;
      });
      this.service.getcoviddata(this.$store.state.auth.user).then((data) => {
        console.log(data);
        if (data.isSuccess === 1) {
          this.dataUser = data.dataInfo;
          this.userFullName =
            data.dataInfo.EMP_FNAME_TH + " " + data.dataInfo.EMP_LNAME_TH;
          if (parseInt(data.dataInfo.TCS_STATE) > 0) {
            this.step = 1;
          } else {
            this.step = 0;
          }
          this.initDatavaccine();
          console.log(this.step);
        }
      });
      this.isLoading = false;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
  methods: {
    initDatavaccine() {
      for (let i = 1; i <= 6; i++) {
        let txtStatus = "";
        let txtVaccine = "";
        let txtDate = "";
        let txtPic = "";
        let txtLastUpdate = "";
        let txtWhere = "";
        if (i == 1) {
          if (this.dataUser.TCS_S1 === "Y") {
            txtStatus = "ฉีดแล้ว";
            txtVaccine = this.dataUser.TCS_S1_VACCINE_NAME;
            txtDate = this.dataUser.TCS_S1_DATE;
            txtPic = this.dataUser.TCS_S1_FILE;
            txtLastUpdate = this.dataUser.TCS_DATETIME_STEP4;
            txtWhere = this.dataUser.TCS_S1_WHERE;
          }
        }
        if (i == 2) {
          if (this.dataUser.TCS_S2 === "Y") {
            txtStatus = "ฉีดแล้ว";
            txtVaccine = this.dataUser.TCS_S2_VACCINE_NAME;
            txtDate = this.dataUser.TCS_S2_DATE;
            txtPic = this.dataUser.TCS_S2_FILE;
            txtLastUpdate = this.dataUser.TCS_DATETIME_STEP5;
            txtWhere = this.dataUser.TCS_S2_WHERE;
          }
        }
        if (i == 3) {
          if (this.dataUser.TCS_S3 === "Y") {
            txtStatus = "ฉีดแล้ว";
            txtVaccine = this.dataUser.TCS_S3_VACCINE_NAME;
            txtDate = this.dataUser.TCS_S3_DATE;
            txtPic = this.dataUser.TCS_S3_FILE;
            txtLastUpdate = this.dataUser.TCS_DATETIME_S3;
            txtWhere = this.dataUser.TCS_S3_WHERE;
          }
        }
        if (i == 4) {
          if (this.dataUser.TCS_S4 === "Y") {
            txtStatus = "ฉีดแล้ว";
            txtVaccine = this.dataUser.TCS_S4_VACCINE_NAME;
            txtDate = this.dataUser.TCS_S4_DATE;
            txtPic = this.dataUser.TCS_S4_FILE;
            txtLastUpdate = this.dataUser.TCS_DATETIME_S4;
            txtWhere = this.dataUser.TCS_S4_WHERE;
          }
        }
        if (i == 5) {
          if (this.dataUser.TCS_S5 === "Y") {
            txtStatus = "ฉีดแล้ว";
            txtVaccine = this.dataUser.TCS_S5_VACCINE_NAME;
            txtDate = this.dataUser.TCS_S5_DATE;
            txtPic = this.dataUser.TCS_S5_FILE;
            txtLastUpdate = this.dataUser.TCS_DATETIME_S5;
            txtWhere = this.dataUser.TCS_S5_WHERE;
          }
        }
        if (i == 6) {
          if (this.dataUser.TCS_S6 === "Y") {
            txtStatus = "ฉีดแล้ว";
            txtVaccine = this.data.TCS_S6_VACCINE_NAME;
            txtDate = this.dataUser.TCS_S6_DATE;
            txtPic = this.dataUser.TCS_S6_FILE;
            txtLastUpdate = this.dataUser.TCS_DATETIME_S6;
            txtWhere = this.dataUser.TCS_S6_WHERE;
          }
        }
        let buffer = {
          id: i,
          status: txtStatus,
          date: txtDate,
          picture: txtPic,
          vaccine: txtVaccine,
          update: txtLastUpdate,
          where: txtWhere,
        };
        this.datavaccine.push(buffer);
      }
    },
    handleLogout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login");
      });
    },
    handleGoBack() {
      window.location.reload();
    },
    handleDelete(id) {
      this.$swal
        .fire({
          position: "bottom-center",
          icon: "question",
          title: "คุณแน่ใจหรือไม่",
          html: "แน่ใจหรือไม่ที่จะเคลียร์ข้อมูลการฉีดวัคซีนเข็มที่ " + id,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          //timer: 1500,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.service.resetcoviddata(this.currentUser, id).then((data) => {
              if (data.isSuccess === 1) {
                this.$swal
                  .fire({
                    position: "bottom-center",
                    icon: "success",
                    title: "ผลการดำเนินการ",
                    html: "ระบบดำเนินการเคลียร์ข้อมูลสำเร็จ",
                    showConfirmButton: true,
                    //timer: 1500,
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  });
              }
            });
          }
        });
    },
    onFileChange(e) {
      this.submitPic = e.target.files[0];
      this.submitPicName = e.target.files[0].name;
      this.submitPicUrl = URL.createObjectURL(this.submitPic);
    },
    onFileChange4(e) {
      this.submitPic4 = e.target.files[0];
      this.submitPicName4 = e.target.files[0].name;
      this.editVData.picture = URL.createObjectURL(this.submitPic4);
    },
    onFileChange5(e) {
      this.submitPic5 = e.target.files[0];
      this.submitPicName5 = e.target.files[0].name;
      this.submitPicUrl5 = URL.createObjectURL(this.submitPic5);
    },
    onFileChange6(e) {
      this.submitPic6 = e.target.files[0];
      this.submitPicName6 = e.target.files[0].name;
      this.submitPicUrl6 = URL.createObjectURL(this.submitPic6);
    },
    handleStep0() {
      let formData = new FormData();
      formData.append("username", this.dataUser.EMP_CODE);
      formData.append("action", "updatecoviddata");
      formData.append("step", "0");
      this.service.updatecovid(formData).then((data) => {
        console.log(data);
        if (data.isSuccess === 1) {
          this.step = 1;
          this.dataUser = data.dataInfo;
        }
      });
    },
    handleStep1() {
      let isError = false;
      let txtError = "";
      if (
        this.dataUser.TCS_NEED === "N" &&
        (this.dataUser.TCS_NO_REMARK === "" || !this.dataUser.TCS_NO_REMARK)
      ) {
        isError = true;
        txtError +=
          "<li>ขอความร่วมมือในการระบุสาเหตุกรณีที่ไม่ประสงค์ฉีดวัคซีน ข้อมูลเป็นความลับค่ะ</li>";
      }
      console.log(this.dataUser);
      if (!isError) {
        let formData = new FormData();
        formData.append("username", this.dataUser.EMP_CODE);
        formData.append("NEED", this.dataUser.TCS_NEED);
        formData.append("CD", this.dataUser.TCS_CD);
        formData.append("NO_REMARK", this.dataUser.TCS_NO_REMARK);
        formData.append("action", "updatecoviddata");
        formData.append("step", "1");
        this.service.updatecovid(formData).then((data) => {
          console.log(data);
          if (data.isSuccess === 1) {
            this.step = 1;
            this.dataUser = data.dataInfo;
            this.step1No = data.registerNo;

            let msg = "ระบบดำเนินการบันทึกข้อมูลเรียบร้อยแล้ว";
            if (data.point > 0) {
              msg +=
                '<br />คุณได้รับ <b class="text-red">' +
                data.point +
                " แต้ม</b> จากการอัพเดทข้อมูล";
            }

            if (data.isWin === 1) {
              this.$swal
                .fire({
                  position: "bottom-center",
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  html: msg,
                  showConfirmButton: true,
                  //timer: 1500,
                })
                .then(() => {
                  this.$swal.fire({
                    position: "bottom-center",
                    html:
                      '<img src="https://b49erp-01.sgp1.digitaloceanspaces.com/p/202/20200625_674ea279c6403683e15bb76d4567b51fe91798caa59e5fb6db220975f10d824c2899c93b177b574ec43d56b60567d7eb8addeb5abaa920823471bc8a8820b07c.jpg" width="250" /><br /><br />คุณได้รับรางวัลพิเศษเป็น<br /><h3 class="text-red mt-1">เสื้อกันฝนบริดจสโตน </h3>กรุณาติดต่อแผนก HR เพื่อรับของรางวัล',
                    showConfirmButton: true,
                    //timer: 1500,
                  });
                });
            } else {
              this.$swal.fire({
                position: "bottom-center",
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                html: msg,
                showConfirmButton: true,
                //timer: 1500,
              });
              this.$swal.fire({
                position: "bottom-center",
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                html: msg,
                showConfirmButton: true,
                //timer: 1500,
              });
            }
          }
        });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
    handleStep2() {
      let isError = false;
      let txtError = "";
      if (
        this.dataUser.TCS_CF === "Y" &&
        (this.dataUser.TCS_CF_WHERE === "" || !this.dataUser.TCS_CF_WHERE)
      ) {
        isError = true;
        txtError +=
          "<li>ขอความร่วมมือในการระบุช่องทางในการจองรับการฉีดวัคซีนค่ะ</li>";
      }
      console.log(this.dataUser);
      if (!isError) {
        let formData = new FormData();
        formData.append("username", this.dataUser.EMP_CODE);
        formData.append("CF", this.dataUser.TCS_CF);
        formData.append("CF_WHERE", this.dataUser.TCS_CF_WHERE);
        formData.append("action", "updatecoviddata");
        formData.append("step", "2");
        this.service.updatecovid(formData).then((data) => {
          console.log(data);
          if (data.isSuccess === 1) {
            this.step = 2;
            this.dataUser = data.dataInfo;

            let msg = "ระบบดำเนินการบันทึกข้อมูลเรียบร้อยแล้ว";
            if (data.point > 0) {
              msg +=
                '<br />คุณได้รับ <b class="text-red">' +
                data.point +
                " แต้ม</b> จากการอัพเดทข้อมูล";
            }
            if (data.isWin === 1) {
              this.$swal
                .fire({
                  position: "bottom-center",
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  html: msg,
                  showConfirmButton: true,
                  //timer: 1500,
                })
                .then(() => {
                  this.$swal.fire({
                    position: "bottom-center",
                    html:
                      '<img src="https://b49erp-01.sgp1.digitaloceanspaces.com/p/202/20200625_674ea279c6403683e15bb76d4567b51fe91798caa59e5fb6db220975f10d824c2899c93b177b574ec43d56b60567d7eb8addeb5abaa920823471bc8a8820b07c.jpg" width="250" /><br /><br />คุณได้รับรางวัลพิเศษเป็น<br /><h3 class="text-red mt-1">เสื้อกันฝนบริดจสโตน </h3>กรุณาติดต่อแผนก HR เพื่อรับของรางวัล',
                    showConfirmButton: true,
                    //timer: 1500,
                  });
                });
            } else {
              this.$swal.fire({
                position: "bottom-center",
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                html: msg,
                showConfirmButton: true,
                //timer: 1500,
              });
            }
          }
        });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
    handleStep3() {
      let isError = false;
      let txtError = "";
      if (
        this.dataUser.TCS_CF_DATE === null ||
        this.dataUser.TCS_CF_DATE === "0000-00-00" ||
        this.dataUser.TCS_CF_DATE === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุวันที่ที่ได้คิวฉีดเข็มที่ 1</li>";
      }
      if (this.submitPic === null || this.submitPic === "") {
        isError = true;
        txtError += "<li>กรุณาถ่ายรูปหรืออัพโหลดเอกสารแนบ</li>";
      }

      console.log(this.dataUser);
      if (!isError) {
        let imgFormData = new FormData();
        imgFormData.append("image", this.submitPic);
        this.service
          .uploadToS3(imgFormData)
          .then((data) => {
            console.log(data);
            let formData = new FormData();
            formData.append("username", this.dataUser.EMP_CODE);
            formData.append("CF_DATE", this.dataUser.TCS_CF_DATE);
            formData.append("CF_FILE", data.Location);
            formData.append("action", "updatecoviddata");
            formData.append("step", "3");
            this.service.updatecovid(formData).then((data) => {
              console.log(data);
              if (data.isSuccess === 1) {
                this.step = 3;
                this.dataUser = data.dataInfo;
                this.submitPicUrl = null;

                let msg = "ระบบดำเนินการบันทึกข้อมูลเรียบร้อยแล้ว";
                if (data.point > 0) {
                  msg +=
                    '<br />คุณได้รับ <b class="text-red">' +
                    data.point +
                    " แต้ม</b> จากการอัพเดทข้อมูล";
                }
                if (data.isWin === 1) {
                  this.$swal
                    .fire({
                      position: "bottom-center",
                      icon: "success",
                      title: "บันทึกข้อมูลสำเร็จ",
                      html: msg,
                      showConfirmButton: true,
                      //timer: 1500,
                    })
                    .then(() => {
                      this.$swal.fire({
                        position: "bottom-center",
                        html:
                          '<img src="https://b49erp-01.sgp1.digitaloceanspaces.com/p/202/20200625_674ea279c6403683e15bb76d4567b51fe91798caa59e5fb6db220975f10d824c2899c93b177b574ec43d56b60567d7eb8addeb5abaa920823471bc8a8820b07c.jpg" width="250" /><br /><br />คุณได้รับรางวัลพิเศษเป็น<br /><h3 class="text-red mt-1">เสื้อกันฝนบริดจสโตน </h3>กรุณาติดต่อแผนก HR เพื่อรับของรางวัล',
                        showConfirmButton: true,
                        //timer: 1500,
                      });
                    });
                } else {
                  this.$swal.fire({
                    position: "bottom-center",
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    html: msg,
                    showConfirmButton: true,
                    //timer: 1500,
                  });
                }
              }
            });
          })
          .catch((error) => console.error("s3-upload", error));
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
    handleStep4() {
      let isError = false;
      let txtError = "";
      if (
        this.editVData.date === null ||
        this.editVData.date === "0000-00-00" ||
        this.editVData.date === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุวันที่ฉีด</li>";
      }
      if (this.editVData.where === null || this.editVData.where === "") {
        isError = true;
        txtError += "<li>กรุณาระบุสถานที่ฉีด</li>";
      }
      if (this.editVData.vaccine === null || this.editVData.vaccine === "") {
        isError = true;
        txtError += "<li>กรุณาระบุวัคซีนที่ฉีด</li>";
      }
      if (this.editVData.picture === null || this.editVData.picture === "") {
        isError = true;
        txtError += "<li>กรุณาถ่ายรูปหรืออัพโหลดเอกสารแนบ</li>";
      }

      console.log(this.dataUser);
      if (!isError) {
        let imgFormData = new FormData();
        imgFormData.append("image", this.submitPic4);
        this.service
          .uploadToS3(imgFormData)
          .then((data) => {
            console.log(data);
            let formData = new FormData();
            formData.append("empcode", this.dataUser.EMP_CODE);
            formData.append("vdate", this.editVData.date);
            formData.append("vwhere", this.editVData.where);
            formData.append("vvaccine", this.editVData.vaccine);
            formData.append("vfile", data.Location);
            formData.append("action", "updatecoviddata2");
            formData.append("vid", this.editVId);
            this.service.updatecovid(formData).then((data) => {
              console.log(data);
              if (data.isSuccess === 1) {
                // this.step = 1;
                // this.dataUser = data.dataInfo;
                // this.submitPicUrl4 = null;

                let msg = "ระบบดำเนินการบันทึกข้อมูลเรียบร้อยแล้ว";

                this.$swal
                  .fire({
                    position: "bottom-center",
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    html: msg,
                    showConfirmButton: true,
                    //timer: 1500,
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  });
              }
            });
          })
          .catch((error) => console.error("s3-upload", error));
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
    handleStep5() {
      let isError = false;
      let txtError = "";
      if (
        this.dataUser.TCS_S2_DATE === null ||
        this.dataUser.TCS_S2_DATE === "0000-00-00" ||
        this.dataUser.TCS_S2_DATE === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุวันที่ฉีด</li>";
      }
      if (
        this.dataUser.TCS_S2_WHERE === null ||
        this.dataUser.TCS_S2_WHERE === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุสถานที่ฉีด</li>";
      }
      if (
        this.dataUser.TCS_S2_VACCINE_NAME === null ||
        this.dataUser.TCS_S2_VACCINE_NAME === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุวัคซีนที่ฉีด</li>";
      }

      if (this.submitPic5 === null || this.submitPic5 === "") {
        isError = true;
        txtError += "<li>กรุณาถ่ายรูปหรืออัพโหลดเอกสารแนบ</li>";
      }

      console.log(this.dataUser);
      if (!isError) {
        let imgFormData = new FormData();
        imgFormData.append("image", this.submitPic5);
        this.service
          .uploadToS3(imgFormData)
          .then((data) => {
            console.log(data);
            let formData = new FormData();
            formData.append("username", this.dataUser.EMP_CODE);
            formData.append("S2_DATE", this.dataUser.TCS_S2_DATE);
            formData.append("S2_WHERE", this.dataUser.TCS_S2_WHERE);
            formData.append(
              "S2_VACCINE_NAME",
              this.dataUser.TCS_S2_VACCINE_NAME
            );

            formData.append("S2_FILE", data.Location);
            formData.append("action", "updatecoviddata");
            formData.append("step", "5");
            this.service.updatecovid(formData).then((data) => {
              console.log(data);
              if (data.isSuccess === 1) {
                this.step = 5;
                this.dataUser = data.dataInfo;
                this.submitPicUrl5 = null;

                let msg = "ระบบดำเนินการบันทึกข้อมูลเรียบร้อยแล้ว";
                if (data.point > 0) {
                  msg +=
                    '<br />คุณได้รับ <b class="text-red">' +
                    data.point +
                    " แต้ม</b> จากการอัพเดทข้อมูล";
                }
                if (data.isWin === 1) {
                  this.$swal
                    .fire({
                      position: "bottom-center",
                      icon: "success",
                      title: "บันทึกข้อมูลสำเร็จ",
                      html: msg,
                      showConfirmButton: true,
                      //timer: 1500,
                    })
                    .then(() => {
                      this.$swal.fire({
                        position: "bottom-center",
                        html:
                          '<img src="https://b49erp-01.sgp1.digitaloceanspaces.com/p/202/20200625_674ea279c6403683e15bb76d4567b51fe91798caa59e5fb6db220975f10d824c2899c93b177b574ec43d56b60567d7eb8addeb5abaa920823471bc8a8820b07c.jpg" width="250" /><br /><br />คุณได้รับรางวัลพิเศษเป็น<br /><h3 class="text-red mt-1">เสื้อกันฝนบริดจสโตน </h3>กรุณาติดต่อแผนก HR เพื่อรับของรางวัล',
                        showConfirmButton: true,
                        //timer: 1500,
                      });
                    });
                } else {
                  this.$swal.fire({
                    position: "bottom-center",
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    html: msg,
                    showConfirmButton: true,
                    //timer: 1500,
                  });
                }
              }
            });
          })
          .catch((error) => console.error("s3-upload", error));
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
    handleStep6() {
      let isError = false;
      let txtError = "";
      if (
        this.dataUser.TCS_S3_DATE === null ||
        this.dataUser.TCS_S3_DATE === "0000-00-00" ||
        this.dataUser.TCS_S3_DATE === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุวันที่ฉีด</li>";
      }
      if (
        this.dataUser.TCS_S3_WHERE === null ||
        this.dataUser.TCS_S3_WHERE === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุสถานที่ฉีด</li>";
      }
      if (
        this.dataUser.TCS_S3_VACCINE_NAME === null ||
        this.dataUser.TCS_S3_VACCINE_NAME === ""
      ) {
        isError = true;
        txtError += "<li>กรุณาระบุวัคซีนที่ฉีด</li>";
      }

      if (this.submitPic6 === null || this.submitPic6 === "") {
        isError = true;
        txtError += "<li>กรุณาถ่ายรูปหรืออัพโหลดเอกสารแนบ</li>";
      }

      console.log(this.dataUser);
      if (!isError) {
        let imgFormData = new FormData();
        imgFormData.append("image", this.submitPic6);
        this.service
          .uploadToS3(imgFormData)
          .then((data) => {
            console.log(data);
            let formData = new FormData();
            formData.append("username", this.dataUser.EMP_CODE);
            formData.append("S3_DATE", this.dataUser.TCS_S3_DATE);
            formData.append("S3_WHERE", this.dataUser.TCS_S3_WHERE);
            formData.append(
              "S3_VACCINE_NAME",
              this.dataUser.TCS_S3_VACCINE_NAME
            );

            formData.append("S3_FILE", data.Location);
            formData.append("action", "updatecoviddata");
            formData.append("step", "6");
            this.service.updatecovid(formData).then((data) => {
              console.log(data);
              if (data.isSuccess === 1) {
                this.step = 6;
                this.dataUser = data.dataInfo;
                this.submitPicUrl6 = null;

                let msg = "ระบบดำเนินการบันทึกข้อมูลเรียบร้อยแล้ว";
                if (data.point > 0) {
                  msg +=
                    '<br />คุณได้รับ <b class="text-red">' +
                    data.point +
                    " แต้ม</b> จากการอัพเดทข้อมูล";
                }
                if (data.isWin === 1) {
                  this.$swal
                    .fire({
                      position: "bottom-center",
                      icon: "success",
                      title: "บันทึกข้อมูลสำเร็จ",
                      html: msg,
                      showConfirmButton: true,
                      //timer: 1500,
                    })
                    .then(() => {
                      this.$swal.fire({
                        position: "bottom-center",
                        html:
                          '<img src="https://b49erp-01.sgp1.digitaloceanspaces.com/p/202/20200625_674ea279c6403683e15bb76d4567b51fe91798caa59e5fb6db220975f10d824c2899c93b177b574ec43d56b60567d7eb8addeb5abaa920823471bc8a8820b07c.jpg" width="250" /><br /><br />คุณได้รับรางวัลพิเศษเป็น<br /><h3 class="text-red mt-1">เสื้อกันฝนบริดจสโตน </h3>กรุณาติดต่อแผนก HR เพื่อรับของรางวัล',
                        showConfirmButton: true,
                        //timer: 1500,
                      });
                    });
                } else {
                  this.$swal.fire({
                    position: "bottom-center",
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    html: msg,
                    showConfirmButton: true,
                    //timer: 1500,
                  });
                }
              }
            });
          })
          .catch((error) => console.error("s3-upload", error));
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },

    handleStep7() {
      let isError = false;
      let txtError = "";
      if (this.dataUser.TMS_NEED === "" || !this.dataUser.TMS_NEED) {
        isError = true;
        txtError += "<li>กรุณาแสดงความประสงค์การฉีดวัคซีนค่ะ</li>";
      }
      if (this.dataUser.TMS_NEED === "Y" && this.dataUser.TCS_NEED === "N") {
        isError = true;
        txtError +=
          '<li>กรุณาเปลี่ยนความประสงค์ในการรับการฉีดวัคซีนในเมนู "อัพเดทความประสงค์" ก่อน</li>';
      } else {
        if (
          this.dataUser.TMS_NEED === "Y" &&
          (this.dataUser.TMS_NEED_VALUE === "" || !this.dataUser.TMS_NEED_VALUE)
        ) {
          isError = true;
          txtError += "<li>กรุณาเลือกประเภทการฉีด</li>";
        }
      }

      // console.log(this.dataUser);
      if (!isError) {
        let formData = new FormData();
        formData.append("EMPCODE", this.dataUser.EMP_CODE);
        formData.append("TMS_NEED", this.dataUser.TMS_NEED);
        formData.append("TMS_NEED_VALUE", this.dataUser.TMS_NEED_VALUE);
        formData.append("action", "updatecoviddata");
        formData.append("step", "7");
        this.service.updatecovid(formData).then((data) => {
          // console.log(data);
          if (data.isSuccess === 1) {
            this.step = 7;
            this.dataUser = data.dataInfo;

            let msg = "ระบบดำเนินการบันทึกข้อมูลเรียบร้อยแล้ว";
            if (data.point > 0) {
              msg +=
                '<br />คุณได้รับ <b class="text-red">' +
                data.point +
                " แต้ม</b> จากการอัพเดทข้อมูล";
            }
            if (data.isWin === 1) {
              this.$swal
                .fire({
                  position: "bottom-center",
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  html: msg,
                  showConfirmButton: true,
                  //timer: 1500,
                })
                .then(() => {
                  this.$swal.fire({
                    position: "bottom-center",
                    html:
                      '<img src="https://b49erp-01.sgp1.digitaloceanspaces.com/p/202/20200625_674ea279c6403683e15bb76d4567b51fe91798caa59e5fb6db220975f10d824c2899c93b177b574ec43d56b60567d7eb8addeb5abaa920823471bc8a8820b07c.jpg" width="250" /><br /><br />คุณได้รับรางวัลพิเศษเป็น<br /><h3 class="text-red mt-1">เสื้อกันฝนบริดจสโตน </h3>กรุณาติดต่อแผนก HR เพื่อรับของรางวัล',
                    showConfirmButton: true,
                    //timer: 1500,
                  });
                });
            } else {
              this.$swal.fire({
                position: "bottom-center",
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                html: msg,
                showConfirmButton: true,
                //timer: 1500,
              });
            }
          } else {
            this.$swal({
              html: "<ul>" + data.errorMsg + "</ul>",
              title: "พบข้อผิดพลาด",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "ตกลง",
            });
          }
        });
      } else {
        this.$swal({
          html: "<ul>" + txtError + "</ul>",
          title: "พบข้อผิดพลาด",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "ตกลง",
        });
      }
    },
  },
};
</script>
<style scoped>
.border-gray-300 {
  border-color: rgba(209, 213, 219, 1);
}
.hand {
  cursor: pointer;
}
.img-thumbnail {
  width: 120px;
}
.w-12 {
  width: 3rem;
}
.h-12 {
  height: 3rem;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.list-group-item {
  cursor: pointer;
}
.list-group-item:hover {
  background: #d1e7dd;
}
.list-group-item a {
  text-decoration: none;
  color: #000;
}
td,
th {
  white-space: nowrap !important;
}
</style>
