<template>
  <div>
    <div class="container">
      <div v-if="!isChecked">
        <div class="form shadow">
          <h3 class="text-red fw-bold m-0 p-0 my-2">เช็คอินรถรับส่งพนักงาน</h3>
          <p class="">กรุณากดเช็คอิน<br />เพื่อทำการบันทึกตำแหน่งที่นั่ง</p>
          หมายเลขรหัสที่นั่ง<br />
          <br />
          <h1 style="font-size: 40px">{{ checkInCode }}</h1>

          <button
            class="form-btn dx btn-green"
            v-if="loggedIn"
            @click="goToCheckIn"
          >
            เช็คอิน
          </button>
          <div class="text-danger" v-if="!loggedIn">
            <br /><br />คุณยังไม่ได้ล็อคอิน <br /><b>กรุณาเข้าสู่ระบบก่อน</b>
          </div>
          <button
            class="form-btn dx btn-red"
            v-if="!loggedIn"
            @click="goToLogin"
          >
            ไปหน้าล็อกอิน &rarr;
          </button>
        </div>
      </div>

      <div v-if="isChecked">
        <div class="form shadow">
          <h1 class="text-success mt-5">คุณเช็คอินสำเร็จ</h1>
          ขอบคุณสำหรับการเช็คอินค่ะ<br />สามารถปิดหน้าจอได้เลยค่ะ
          <button class="form-btn dx " @click="goToClose">
            ปิดหน้าจอ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/auth";
export default {
  name: "BusCheckIn",
  data() {
    return {
      checkInCode: null,
      returnUrl: null,
      isChecked: false,
    };
  },
  components: {},

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getReturnUrl() {
      return this.$store.state.returnUrl;
    },
    currentPath() {
      // We will see what `params` is shortly
      return this.$route.path;
    },
    getCheckInCode() {
      // We will see what `params` is shortly
      return this.$route.params.id;
    },
  },
  service: null,
  created() {
    this.service = AuthService;
  },
  methods: {
    goToCheckIn() {
      let formData = new FormData();
      formData.append("empcode", this.currentUser);
      formData.append("buscode", this.getCheckInCode);
      formData.append("action", "buscheckin");
      this.service.buscheckin(formData).then((data) => {
        console.log(data);
        if (data.isSuccess === 1) {
          this.$swal.fire({
            position: "bottom-center",
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
            html: "ดำเนินการเช็คอินสำเร็จ",
            showConfirmButton: true,
            //timer: 1500,
          });
          this.isChecked = true;
        }
      });
    },
    goToLogin() {
      this.$store.dispatch(
        "changeRedirect",
        "/bus-checkin/" + this.getCheckInCode
      );
      this.$router.push("/login");
    },
    goToClose() {
      window.close();
    },
  },
  mounted() {
    this.checkInCode = this.getCheckInCode;
  },
};
</script>
<style scoped>
.container {
  position: absolute;
  width: auto;
  height: auto;
  top: calc(50% - 280px);
  left: calc(50% - 187px);
}

.form {
  position: absolute;
  text-align: center;
  background: #fff;
  width: 350px;
  height: 470px;
  border-radius: 5px;
  padding: 30px 20px 0 20px;
  /* box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box; */
}

p {
  margin-bottom: 40px;
}

p > span {
  padding-top: 3px;
  display: block;
  font-weight: 400;
}

h3 {
  margin-bottom: 30px;
}

input,
button {
  outline: none !important;
}

button.form-btn {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
  border: 0;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
}

button.form-btn.sx {
  left: 0;
  border-radius: 0 0 0 5px;
  background-color: #999;
  color: #fff;
  transition: all 0.3s linear;
}

button.form-btn.sx:hover {
  background-color: #666;
  color: #fff;
}

button.form-btn.sx.back {
  background-color: #999;
  transition: all 0.3s linear;
}

button.form-btn.sx.back:hover {
  background-color: #666;
}

button.form-btn.dx {
  right: 0;
  background-color: #999;

  border-radius: 0 0 5px 0;
  color: #fff;
}
.btn-red {
  background-color: #df0007 !important;
  color: #fff;
}

.btn-green {
  background-color: #198754 !important;
  color: #fff;
}
input {
  border: none;
  border-bottom: 1px solid #eee;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-size: 18px;
}
</style>
