<template>
  <Header />
  <router-view />
  <br /><br /><br /><br /><br /><br />
</template>
<script>
import Header from "@/components/Header.vue";
import { isMobile } from "mobile-device-detect";
export default {
  name: "App",
  data() {
    return { isMobile };
  },
  components: { Header },
};
</script>
<style>
html,
body,
#app {
  /* font-family: "DBHelvethaicaX", sans-serif; */
  font-family: "Sukhumvit Set", sans-serif;
  background: #f5f5f5;
}
#app .text-normal .font-bold {
  font-family: "DBHelvethaicaX-Bd";
  font-weight: normal;
}
.swal2-container {
  font-family: "Sukhumvit Set", sans-serif !important;
  font-size: 15px !important;
}
.swal2-container ul {
  list-style: disc;
  margin-left: 0px;
  text-align: left !important;
}
.swal2-content {
  font-size: 16px !important;
}
.swal2-title {
  font-size: 24px !important;
  font-weight: 900 !important;
}
.swal2-popup .swal2-styled.swal2-confirm {
  background: #206600 !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  border-radius: 25px !important;
  padding: 10px 30px;
}
.swal2-icon-error .swal2-title {
  color: #df0007 !important;
}
.swal2-icon.swal2-error {
  border-color: #df0007 !important;
  color: #df0007 !important;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background: #df0007 !important;
}
.swal2-icon-question .swal2-styled.swal2-cancel,
.swal2-icon-error .swal2-styled.swal2-confirm {
  background: #df0007 !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  border-radius: 25px !important;
  padding: 10px 30px;
}
.swal2-icon-success .swal2-styled.swal2-confirm {
  background: #206600 !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  border-radius: 25px !important;
  padding: 10px 30px;
}
</style>
